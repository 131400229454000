var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{staticClass:"mt-5 mb-5"},[_vm._v("Company component")]),_c('router-view'),_c('table-component',{attrs:{"arrayColumns":_vm.arrayColumns,"data":_vm.array},scopedSlots:_vm._u([{key:"idPrueba",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v("Multi: "+_vm._s(data.id * 100))])]}},{key:"u_roles",fn:function(ref){
var data = ref.data;
return [_c('div',_vm._l((data.u_roles),function(items,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(items.name)+" ")])}),0)]}},{key:"category",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('p',[_vm._v(_vm._s(data.category.name))]),_c('p',[_vm._v(_vm._s(data.category.company.name))])])]}},{key:"config",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"actions-table"},[_c('smart-icon',{staticClass:"icon",attrs:{"icon":"Calendarv1Icon","size":"24"},on:{"click":_vm.clickBtn}}),_c('smart-icon',{staticClass:"icon",attrs:{"icon":"Calendarv1Icon","size":"24"},on:{"click":_vm.clickBtn}}),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"MoreVerticalIcon","size":"24"},on:{"click":_vm.clickBtn}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }