<template>
    <div class="table-component">
        <div
            class="container-table container-field"
            :style="{
                gridTemplateColumns: `repeat(${arrayColumns.length}, 1fr)`
            }"
        >
            <span v-for="(column, index) in arrayColumns" :key="index">{{
                column.label ? column.label : column.key
            }}</span>
        </div>

        <div class="container-table">
            <div class="">
                <div
                    :style="{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${arrayColumns.length}, 1fr)`
                    }"
                    class=""
                    v-for="(row, index) in data"
                    :key="index"
                >
                    <template v-for="column in arrayColumns">
                        <div class="cell" :key="column.key">
                            <slot :name="column.key" :data="data[index]">
                                <p>{{ data[index][`${column.key}`] }}</p>
                            </slot>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!-- <cell-row
            v-for="(row, index) in data"
            :row="row"
            :key="index"
            :arrayColumns="arrayColumns"
            :class="[index + 1 == data.length ? 'ultimo' : '']"
        >
            
        </cell-row> -->
    </div>
</template>

<script>
export default {
    components: {  },
    name: "TableComponent",
    props: ["arrayColumns", "data"],
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.table-component {
    box-shadow: 1px 2px 5px rgb(0 0 0 / 10%);
    background: #fff;
    //padding: 2rem;
    border-radius: 6px;
    //  overflow: hidden;
    min-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    @media screen and (min-width: 1000px) {
        overflow-x: hidden;
    }
}
.container-table {
    min-width: 800px;
    display: grid;
    &:last-child {
        //border-bottom: 9px solid white !important;
        .cell-table {
        }
    }
}
.ultimo {
    .cell-table {
        border-bottom: none !important;
    }
}
.container-field {
    padding: 0.5rem 0;
    background: #ff4d4d;
    span {
        text-align: center;

        font-weight: bolder;
        color: #fff;
        border-right: 1px solid #fff;
        &:last-child {
            border: none;
        }
    }
}
.cell{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    p{
        margin: 0;
    }
}
</style>
