<template>
    <div class="container">
        <h1 class="mt-5 mb-5">Company component</h1>
        <router-view></router-view>

        <table-component :arrayColumns="arrayColumns" :data="array">

            <template v-slot:idPrueba="{ data }">
                <p>Multi: {{ data.id * 100 }}</p>
            </template>

            <!-- <template v-slot:id="{ data }">
                <p>ID: {{ data.id }}</p>
            </template> -->

            <!-- <template v-slot:name="{ data }">
                <p>{{ data.name }}</p>
            </template> -->

            <template v-slot:u_roles="{ data }">
                <div>
                    <p v-for="(items, index) in data.u_roles" :key="index">
                        {{ items.name }}
                    </p>
                </div>
            </template>

            <template v-slot:category="{ data }">
                <div>
                    <p>{{ data.category.name }}</p>
                    <p>{{ data.category.company.name }}</p>
                </div>
            </template>

            <!-- <template v-slot:status="{ data }">
                <p>{{ data.status }}</p>
            </template> -->

            <template v-slot:config="{ data }">
                <div class="actions-table">
                    <smart-icon
                        icon="Calendarv1Icon"
                        size="24"
                        class="icon"
                        @click="clickBtn"
                    />
                    <smart-icon
                        icon="Calendarv1Icon"
                        size="24"
                        class="icon"
                        @click="clickBtn"
                    />
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="24"
                        class="icon"
                        @click="clickBtn"
                    />
                </div>
            </template>

        </table-component>
    </div>
</template>

<script>
import TableComponent from "./TableComponent.vue";

export default {
    name: "CompanyComponent",
    components: { TableComponent },
    data() {
        return {
            arrayColumns: [
                {
                    key: "idPrueba",
                    label: "Prueba",
                    class: "text-center column-table",
                    tdClass: "py-1"
                },
                {
                    key: "id",
                    label: "Id",
                    class: "text-center column-table",
                    tdClass: "py-1"
                },
                {
                    key: "name",
                    label: "Nombre",
                    class: "text-left name-tournament",
                    tdClass: "py-1"
                },
                {
                    key: "u_roles",
                    label: "Roles",
                    class: "text-left",
                    tdClass: "py-1"
                },
                {
                    key: "category",
                    label: "Category",
                    class: "text-left",
                    tdClass: "py-1"
                },
                {
                    key: "status",
                    label: "status",
                    class: "text-left",
                    tdClass: "py-1"
                },
                {
                    key: "config",
                    label: "Config",
                    class: "text-left",
                    tdClass: "py-1"
                }
            ],
            array: [
                {
                    avatar: null,
                    cc: 5,
                    email: "encastador@gmail.com",
                    id: 1,
                    name: "Roger Segura",
                    name_user: "Juan Carlos Dominguez Reyes",
                    status: "Activo",
                    t_access_log: "11/03/2022 12:20:23 PM",
                    u_roles: [
                        {
                            id: 1,
                            name: "admin"
                        }
                    ],
                    category: {
                        name: "Category 1",
                        company: {
                            id: 1,
                            name: "Roger SAC"
                        }
                    },
                    updated_at: "2022-03-07 09:24:14"
                },
                {
                    avatar: null,
                    cc: 5,
                    email: "janasarii@gmail.com",
                    id: 2,
                    name: "Jack Sari",
                    name_user: "Juan Carlos Dominguez Reyes",
                    status: "Activo",
                    t_access_log: "11/03/2022 12:20:23 PM",
                    u_roles: [
                        {
                            id: 2,
                            name: "encastador"
                        },
                        {
                            id: 3,
                            name: "criador"
                        }
                    ],
                    category: {
                        name: "Category 1",
                        company: {
                            id: 1,
                            name: "Sari SAC"
                        }
                    },
                    updated_at: "2022-03-07 09:24:14"
                },
                {
                    avatar: null,
                    cc: 5,
                    email: "jacksari@gmail.com",
                    id: 3,
                    name: "Jack Sari v2",
                    name_user: "Juan Carlos Dominguez Reyes",
                    status: "Inactive",
                    t_access_log: "11/03/2022 12:20:23 PM",
                    u_roles: [
                        {
                            id: 3,
                            name: "criador"
                        }
                    ],
                    category: {
                        name: "Category 2",
                        company: {
                            id: 1,
                            name: "Janasari SAC"
                        }
                    },
                    updated_at: "2022-03-07 09:24:14"
                },
                {
                    avatar: null,
                    cc: 6,
                    email: "jacksari@gmail.com",
                    id: 4,
                    name: "Jack Sari v3",
                    name_user: "Jack Janasari",
                    //status: "Inactive",
                    t_access_log: "08/05/2022 12:20:23 PM",
                    
                    category: {
                        name: "Category 3",
                        company: {
                            id: 1,
                            name: "Sari SAC"
                        }
                    },
                    updated_at: "2022-03-07 09:24:14"
                }
            ]
        };
    },
    mounted() {},
    methods: {
        clickBtn() {
            alert("Soy una alerta");
        }
    }
};
</script>

<style lang="scss" scoped>
.container-table {
    display: grid;
}
.actions-table {
    .icon {
        margin: 0 0.2rem;
        cursor: pointer;
    }
}
</style>
